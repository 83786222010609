<template lang="pug">
#thank-you.step-component
  icon.ty-icon(data="@icon/welcome-image.svg")
  h1 Thank You For Completing the Kit!
  p You may now close and delete the app.
  p Please drop the box off at your local {{ dropLocation }} <strong>{{dropOffText}}</strong>.
  button(v-if="this.$route.meta.webViewContainer", @click="end") Return to the {{ this.$route.meta.webViewContainer }}
  p.contact-kit If you have any questions, please contact us at #[a(href="mailto:help@kit.com") help@kit.com].
</template>

<script>
import CacheApi from '@/utils/cache-api'
import AmplitudeAPI from '@/utils/amplitude'
import Vue from 'vue'

export default {
  props: {
    dropLocation: {
      type: String,
      required: true,
    },
    checkpointPosition: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      todaysDate: new Date()
    }
  },

  mounted() {
    this.cleanupLocalStorage()
    this.cleanupCache()
    if (this.dropLocation == 'UPS') {
      this.$store.dispatch('user/completedKit')
    }
  },

  computed: {
    dropOffText() {
      if (localStorage.getItem('displayWarning') == 'false') {
        return 'by the end of the day'
      }
      var text
      switch(this.todaysDate.getDay()) {
        case 0:
          text = 'by tomorrow morning'
          break
        case 5:
        case 6:
          text = 'by 1 PM'
          break
        default:
          text = 'by the end of the day'
          break
      }
      return text
    }
  },

  watch: {},

  methods: {
    cleanupCache,
    cleanupLocalStorage,
    end,
  },
  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function cleanupCache() {
  AmplitudeAPI.logEvent('KitCompleted', {
    kit_type: this.$store.getters['user/getKitType'],
    medplum_id: this.$store.getters['user/getMedplumOrderId'],
    barcode: this.$store.getters['user/getBarcodeId'],
    sku: this.$store.getters['user/getSku'],
    linked_amplitude_id: this.$route.query.q,
    customer: this.$store.getters['user/getConfigKeys'] ? this.$store.getters['user/getConfigKeys'].carrier : null,
    end_date: new Date().toISOString(),
    source: this.$route.query.src,
  })
  Vue.axios.post('/app-checkpoint', {
    orderId: this.$store.getters['user/getMedplumOrderId'],
    checkpointPosition: this.checkpointPosition,
  })
  this.cacheApi = new CacheApi()
  this.cacheApi.clearVideoCache()
}

function cleanupLocalStorage() {
  localStorage.removeItem('currentSection')
  localStorage.removeItem('currentStep')
}

function end() {
  ReactNativeWebView.postMessage('user_ended')
}
</script>
